var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.bcsComponent, {
        tag: "component",
        attrs: {
          popupParam: _vm.popupParam,
          survey: _vm.survey,
          evalItems: _vm.evalItems,
          evalItemClasses: _vm.evalItemClasses,
          contentHeight: _vm.contentHeight,
          isOld: _vm.isOld,
        },
        on: {
          "update:popupParam": function ($event) {
            _vm.popupParam = $event
          },
          "update:popup-param": function ($event) {
            _vm.popupParam = $event
          },
          "update:survey": function ($event) {
            _vm.survey = $event
          },
          "update:evalItems": function ($event) {
            _vm.evalItems = $event
          },
          "update:eval-items": function ($event) {
            _vm.evalItems = $event
          },
          "update:evalItemClasses": function ($event) {
            _vm.evalItemClasses = $event
          },
          "update:eval-item-classes": function ($event) {
            _vm.evalItemClasses = $event
          },
          "update:isOld": function ($event) {
            _vm.isOld = $event
          },
          "update:is-old": function ($event) {
            _vm.isOld = $event
          },
          getDetail: _vm.getDetail,
          closePopup: _vm.closePopup,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }